/* global $ */
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import Alert from "react-bootstrap/Alert";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";

export default function AddGroup() {
  const [banks, setBanks] = useState(['EQUITY', 'BACLAYS', "STANBIC", 'FAMILY']);
  const [accounts, setAccounts] = useState([]);
  const [bankName, setBankName] = useState("");
  const [documentTypes, setdocumentTypes] = useState(["GROUP REGISTRATION CERTIFICATE",
    "GROUP KRA CERTIFICATE"
  ]);
  const [GroupDocuments, setGroupDocuments] = useState([]);
  const [GroupMembers, setGroupMembers] = useState([]);



  //Group details
  const [GroupDetails, setGroupDetails] = useState({
    GroupTypeName: "INDIVIDUAL"
  });
  const onChangeGroupDetails = (e) => {


    setGroupDetails({
      ...GroupDetails,
      [e.target.name]: e.target.value,
    });
  };

  //modals
  const [show, setShow] = useState(false);
  const [docShow, setdocShow] = useState(false);
  const [memberShow, setMemberShow] = useState(false);

  const [editAccountShow, seteditAccountShow] = useState(false);
  const [editDocShow, seteditDocShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleDocShow = () => setdocShow(true);
  const handleDocClose = () => setdocShow(false);

  const handleMemberShow = () => setMemberShow(true);
  const handleMemberClose = () => setMemberShow(false);

  const handleEditShow = () => seteditDocShow(true);
  const handleEditClose = () => seteditDocShow(false);

  const handleEditAccountShow = () => seteditAccountShow(true);
  const handleEditAccountClose = () => seteditAccountShow(false);

  // document details
  const [docName11, setdocName11] = useState("");
  const [docName, setdocName] = useState("");
  const [document1, setdocument] = useState("");
  const [document11, setdocument11] = useState("");
  const [idNumber, setidNumber] = useState("");
  const [designation, setDesignation] = useState("");
  const [documentTypeId, setdocumentTypeId] = useState(null);

  const [bankAccountNumber, setbankAccountNumber] = useState("");
  const [bankId, setbankId] = useState(null);


  const setbankAccountDetails = (value) => {
    setbankId(value.split(":")[0]);
    setBankName(value.split(":")[1]);
  };
  const editBankAccountDetails = (value) => {
    setEditBankId(value.split(":")[0]);
    setEditBankName(value.split(":")[1]);
  };

  //bank edits
  const [editBankId, setEditBankId] = useState(null);
  const [editBankName, setEditBankName] = useState("");
  const [editBankAccount, setEditBankAccount] = useState("");
  const [editpercentageRemuneration, setEditPercentageRemuneration] =
    useState(null);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [arr_index, setArr_Index] = useState(0);

  const getOneAccount = (id) => {
    setArr_Index(id);
    setEditBankName(selectedAccount.bankName);
    handleEditAccountShow();
  };


  const handleDocumentSubmit = (event) => {
    event.preventDefault();
    let data = {
      docName: docName,
      document: document1,
      documentOwnerTypeName: "Group",
      documentTypeId: documentTypeId,
      id: null,
      ownerEntityId: null,
    };
    setGroupDocuments((GroupDocuments) => [...GroupDocuments, data]);
    handleDocClose();
  };


  const handleMemberSubmit = (event) => {
    event.preventDefault();
    let data = {
      memberName: docName11,
      phone: document11,
      idNumber: idNumber,
      designation: designation != "" ? designation : undefined,
    };
    setGroupMembers((GroupMembers) => [...GroupMembers, data]);
    handleMemberClose();
  };

  const getDoc = (id) => {
    console.log(accounts[id]);
  };

  const handleEditDocument = (event) => {
    event.preventDefault();
    console.log(accounts);
  };
  const [error, setError] = useState({
    message: "",
    color: "",
  });

  //accounts edit

  const handleEditAccount = (event) => {
    event.preventDefault();
    let data = {
      bankName: editBankName,
      active: true,
      bankAccountNumber: editBankAccount,
      bankId: editBankId,
      id: null,
      GroupId: null,
      percentageRemuneration: editpercentageRemuneration,
    };
    let d = accounts;
    d[arr_index] = data;
    setAccounts(d);
    handleEditAccountClose();
  };

  const [missingItems, setMissingItems] = useState([]);
  const [check, setCheck] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setCheck(false);
    setMissingItems([]);
    var temp = [];

    let new_t = {
      group: GroupDetails,
      bankAccounts: accounts,
      members: GroupMembers,
      approved:false
    };

    console.log("new_t");
    console.log("new_t");
    console.log(new_t);

    let all =[];
    let data1 = localStorage.getItem("data");
    if (data1 != null){
      let b=JSON.parse(data1)
      for (let i=0;i<b.length;i++)
      all.push(b[i])
      all.push(new_t)
    }else{
      all.push(new_t)
    }

    localStorage.setItem("data", JSON.stringify(all));

    sendMessagesAfterApproval(GroupMembers,GroupDetails.groupName);

    confirmAlert({
      message: 'Registration successful!',
      buttons: [
        {
          label: "OK",onClick:()=>window.location.href="/"
        },
      ],
    });

  };

  const sendMessagesAfterApproval = (phones, groupname) => {
    for (let i = 0; i < phones.length; i++) {
      let data = {
        "channel_id": 1,
        "contact_id": 0,
        "template_id": 0,
        "message_language": "ENG",
        "placeholder_entries": "{}",
        "message_type": "SMS",
        "sender": "SWIFTCOINS",
        "recipient": phones[i].phone,
        "subject": "",
        "message": "Dear " + phones[i].memberName +
        ", your group "+groupname+" has submitted an application with the Hustler Fund. You will be notified when the approval is completed.",
        "message_hash": "",
        "response": "",
        "message_status": "NEW"
      }

      let headers = {
        "Authorization": "Basic c3dpZnRjb2luc3VzZXI6cnpCcFJMVFVCcDEwQlRQ",
        "Content-Type": "application/json"
      }
      axios.post("https://messaging.swiftcoins.co.ke/api/v1/messages", data, {
        headers: headers
      }).then((response) => {

        console.log("JSON.stringify(response) for " + phones[i]);
        console.log(JSON.stringify(response));

      })
        .catch((error) => {
          console.log("JSON.stringify(error) for " + phones[i]);
          console.log(JSON.stringify(error));
        })
    }
  }



  const removeDoc = (id) => {
    setGroupDocuments(
      GroupDocuments.filter((Groupdoc) => Groupdoc.docName !== id)
    );
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setdocument(base64);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleAccountSubmit = (event) => {
    event.preventDefault();
    let data = {
      bankName: bankName,
      active: true,
      bankAccountNumber: bankAccountNumber,
      bankId: bankId,
      id: null,
      GroupId: null,
      percentageRemuneration: 0,
    };
    setAccounts((accounts) => [...accounts, data]);
    setbankAccountNumber("");
    setBankName("");
    setbankId(null);
    setShow(false);
  };



  const getDocName = (docId) => {
    return documentTypes?.filter((x) => x.id === parseInt(docId))[0]?.name;
  };

  // LOADER ANIMATION
  useEffect(() => {
    $("#spinner").removeClass("d-none");
    setTimeout(() => {
      $("#spinner").addClass("d-none");
    }, 1000);
  }, [])
  return (
    <>
      <div className="page-content">
        <div className="content-fluid">
          {/* <!-- start page title --> */}
          <div className="row">
            {/* <!-- Loader --> */}
            <div id="spinner">
              <div id="status">
                <div class="spinner-chase">
                  <div class="chase-dot"></div>
                  <div class="chase-dot"></div>
                  <div class="chase-dot"></div>
                  <div class="chase-dot"></div>
                  <div class="chase-dot"></div>
                  <div class="chase-dot"></div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18 text-capitalize">
                  Group Registration
                </h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="index.html">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="property-list.html">Groups</a>
                    </li>
                    <li className="breadcrumb-item active">Add a Group</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {error.color !== "" && (
              <div className={"alert alert-" + error.color} role="alert">
                {error.message}
              </div>
            )}

            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="create-property" id="kev-step-form">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light text-black">
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav mr-auto">
                          <li className="nav-item active">
                            <a className="nav-link active">
                              1. Group details{" "}
                              <span className="sr-only">(current)</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link">2. Member details</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link">3. Bank Account details</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link">3. Document attachments</a>
                          </li>
                        </ul>
                      </div>
                    </nav>
                    {/*section add Group details*/}
                    <form id="my-form">
                      <section className={"step-cont active-step"}>
                        <p>
                          Fill in the form correctly. Fields with an Asterisk{" "}
                          <strong className="text-danger">*</strong> are
                          mandatory fields.
                        </p>

                        <div className="col-12">
                          <div className="bg-primary border-indigo-700 p-3 mb-4">
                            <p className="fw-semibold mb-0 pb-0 text-uppercase">
                              Group details
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12 col-md-12 ">
                            <div className="mb-4 ">
                              <label htmlFor="basicpill-firstname-input ">
                                Group name{" "}
                                <strong class="text-danger ">*</strong>
                              </label>
                              <input
                                type="text "
                                className="form-control"
                                name={"groupName"}
                                onChange={(e) => onChangeGroupDetails(e)}
                                id="basicpill-firstname-input "
                                required
                                placeholder="Enter the group Name "
                              />
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6 ">
                            <div className="mb-3">
                              <label
                                htmlFor="Group-type"
                                className="form-label"
                              >
                                Group sector.{" "}
                                <strong class="text-danger ">*</strong>
                              </label>

                              <div className="form-group mb-4">
                                <select
                                  className="form-control text-capitalize"
                                  name={"sector"}
                                  onChange={(e) => onChangeGroupDetails(e)}
                                >
                                  <option
                                    className="text-black font-semibold"
                                    value={"INDIVIDUAL"}
                                  >
                                    select Group type
                                  </option>

                                  <option>--Select--</option>
                                  <option value="Agriculture">Agriculture</option>
                                  <option value="Tourism">Tourism</option>
                                  <option value="Manufacturing">Manufacturing</option>
                                  <option value="Transport and Infrastructure">Transport and Infrastructure</option>
                                  <option value="Information & Communications Technology">Information & Communications Technology</option>
                                  <option value="Building & Construction">Building & Construction</option>
                                  <option value="Education, Art & Music">Education, Art & Music</option>
                                  <option value="Financial Services">Financial Services</option>
                                  <option value="Jua Kali">Jua Kali</option>
                                  <option value="General Trade">General Trade</option>
                                  <option value="Other">Other</option>
                                </select>
                              </div>

                            </div>
                          </div>

                          {GroupDetails.sector === "Other" &&

                            <div className="col-lg-3 col-md-6 ">
                              <div className="mb-4 ">
                                <label for="">Group Sector.</label>
                                <input
                                  type="text "
                                  className="form-control "
                                  id=" "
                                  name={"otherSectorName"}
                                  onChange={(e) => onChangeGroupDetails(e)}
                                  placeholder="Enter group sector"
                                />
                              </div>
                            </div>
                          }


                          <div className="col-lg-3 col-md-6 ">
                            <div className="mb-4 ">
                              <label htmlFor=" ">Registration No. </label>
                              <input
                                type="text "
                                className="form-control "
                                id=" "
                                name={"registrationNumber"}
                                onChange={(e) => onChangeGroupDetails(e)}
                                placeholder="Enter registration Number"
                              />
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6 ">
                            <div className="mb-4 ">
                              <label htmlFor="basicpill-lastname-input ">
                                No. of Members{" "}
                                <strong class="text-danger ">*</strong>
                              </label>
                              <input
                                type="number " min={0} max={100}
                                className="form-control"
                                name={"noOfMembers"}
                                onChange={(e) => onChangeGroupDetails(e)}
                                id="basicpill-lastname-input "
                                placeholder="No. of Members "
                                required
                              />
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6 ">
                            <div className="mb-4 ">
                              <label htmlFor="basicpill-phoneno-input ">
                                Amount being requested <strong class="text-danger ">*</strong>
                              </label>
                              <input
                                type="number "
                                className="form-control "
                                id="basicpill-phoneno-input "
                                name={"requestedAmount"}
                                onChange={(e) => onChangeGroupDetails(e)}
                                placeholder="Enter amount "
                              />
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6 ">
                            <div className="mb-4 ">
                              <label for="" class="">Active: <strong class="text-danger">*</strong></label>


                              <select
                                className="form-control text-capitalize"
                                name={"active"}
                                onChange={(e) => onChangeGroupDetails(e)}
                              >

                                <option>--Select--</option>
                                <option value={true}>True</option>
                                <option value={false}>False</option>
                              </select>

                            </div>

                          </div>

                          <div className="col-lg-3 col-md-6 ">
                            <div className="mb-4 ">
                              <label for="" class="">Annual Income range: <strong class="text-danger">*</strong></label>


                              <select
                                className="form-control text-capitalize"
                                name={"annualIncomeRange"}
                                onChange={(e) => onChangeGroupDetails(e)}
                              >

                                <option>--Select--</option>
                                <option value={"Below 100,000"}>Below 100,000</option>
                                <option value={"100,001 - 250,000"}>100,001 - 250,000</option>
                                <option value={"250,001 - 500,000"}>250,001 - 500,000</option>
                                <option value={"Above 500,000"}>Above 500,000</option>
                              </select>

                            </div>

                          </div>

                          <div className="col-lg-3 col-md-6 ">
                            <div className="mb-4 ">
                              <label for="" class="">Target Income Range: <strong class="text-danger">*</strong></label>


                              <select
                                className="form-control text-capitalize"
                                name={"targetIncomeRange"}
                                onChange={(e) => onChangeGroupDetails(e)}
                              >

                                <option>--Select--</option>
                                <option value={"Below 100,000"}>Below 100,000</option>
                                <option value={"100,001 - 250,000"}>100,001 - 250,000</option>
                                <option value={"250,001 - 500,000"}>250,001 - 500,000</option>
                                <option value={"Above 500,000"}>Above 500,000</option>
                              </select>

                            </div>

                          </div>
                        </div>

                      </section>

                      <section className={"step-cont d-none"}>
                        <div className="col-12">
                          <div className="bg-primary border-2 bg-soft p-3 mb-4">
                            <p className="fw-semibold mb-0 pb-0 text-uppercase">
                              Group Members{" "}
                            </p>
                          </div>
                        </div>
                        <div className="table-responsive table-responsive-md">
                          <table className="table table-editable-file align-middle table-edits">
                            <thead className="table-light">
                              <tr className="text-uppercase table-dark">
                                <th className="vertical-align-middle">#</th>
                                <th className="vertical-align-middle">
                                  Member name
                                </th>
                                <th>ID Number</th>
                                <th className="vertical-align-middle">
                                  Phone Number
                                </th>
                                <th className="vertical-align-middle">
                                  Designation
                                </th>
                                <th className="text-right"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {GroupMembers.length > 0 &&
                                GroupMembers?.map((doc, index) => {
                                  return (
                                    <tr data-id="1" key={index}>
                                      <td style={{ width: "80px" }}>
                                        {index + 1}
                                      </td>
                                      <td>{doc.memberName}</td>

                                      <td>{doc.idNumber}</td>
                                      <td>{doc.phone}</td>
                                      <td>{doc.designation}</td>
                                      <td></td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td
                                  colSpan="7"
                                  onClick={handleMemberShow}
                                  className="bg-light cursor-pointer"
                                >
                                  <span className="d-flex align-items-center ">
                                    <i className="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i>
                                    <span className="pl-5 ">
                                      Add A Member
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </section>

                      {/*add bank accounts*/}
                      <section className={"step-cont d-none"}>
                        <div className="col-12">
                          <div className="table-responsive table-responsive-md">
                            <div className="bg-primary border-2 bg-soft p-3 mb-4">
                              <p className="fw-semibold mb-0 pb-0 text-uppercase">
                                BANKING DETAILS
                              </p>
                            </div>
                            <table className="table table-editable-1 align-middle table-edits">
                              <thead className="table-light">
                                <tr className="text-uppercase table-dark">
                                  <th>#</th>
                                  <th>Bank</th>
                                  <th className="">Bank Acc</th>
                                  <th>Status</th>
                                  <th>Actions</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {accounts.length > 0 &&
                                  accounts?.map((account, index) => {
                                    return (
                                      <tr data-id="1" key={index}>
                                        <td style={{ width: "80px" }}>
                                          {index + 1}
                                        </td>
                                        <td>{account.bankName}</td>
                                        <td>{account.bankAccountNumber}</td>
                                        <td data-field="unit-num ">
                                          {account.active ? (
                                            <span className="badge-soft-success badge">
                                              Active
                                            </span>
                                          ) : (
                                            <span className="badge-soft-danger badge">
                                              Inactive
                                            </span>
                                          )}
                                        </td>
                                        <td className="text-right cell-change text-nowrap ">
                                          <div className="d-flex">
                                            <a
                                              data-bs-toggle="modal"
                                              onClick={() =>
                                                getOneAccount(index)
                                              }
                                              data-bs-target="#update-modal"
                                              className="btn btn-light btn-rounded waves-effect btn-circle btn-transparent edit "
                                              title="Edit "
                                            >
                                              <i className="bx bx-edit-alt "></i>
                                            </a>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                              <tfoot>
                                <tr className="" onClick={handleShow}>
                                  <td
                                    colSpan="7"
                                    className="bg-light cursor-pointer"
                                  >
                                    <span className="d-flex align-items-center ">
                                      <i className="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i>
                                      <span className="pl-5 ">Add a Bank</span>
                                    </span>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </section>

                      {/*add documents */}
                      <section className={"step-cont d-none"}>
                        <div className="col-12">
                          <div className="bg-primary border-2 bg-soft p-3 mb-4">
                            <p className="fw-semibold mb-0 pb-0 text-uppercase">
                              Document Attachments{" "}
                            </p>
                          </div>
                        </div>
                        <div className="table-responsive table-responsive-md">
                          <table className="table table-editable-file align-middle table-edits">
                            <thead className="table-light">
                              <tr className="text-uppercase table-dark">
                                <th className="vertical-align-middle">#</th>
                                <th className="vertical-align-middle">
                                  Document owner
                                </th>
                                <th>Document Type</th>
                                <th className="vertical-align-middle">
                                  Document Name
                                </th>
                                <th className="vertical-align-middle">
                                  Actions
                                </th>
                                <th className="text-right"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {GroupDocuments.length > 0 &&
                                GroupDocuments?.map((doc, index) => {
                                  return (
                                    <tr data-id="1" key={index}>
                                      <td style={{ width: "80px" }}>
                                        {index + 1}
                                      </td>
                                      <td>{doc.documentOwnerTypeName}</td>
                                      <td>
                                        {documentTypes.length > 0
                                          ? getDocName(doc.documentTypeId)
                                          : ""}
                                      </td>
                                      <td>{doc.docName}</td>
                                      <td className="text-right cell-change text-nowrap ">
                                        <div className="d-flex">
                                          <a
                                            data-bs-toggle="modal"
                                            data-bs-target="#update-modal"
                                            onClick={() =>
                                              removeDoc(doc.docName)
                                            }
                                            className="btn btn-light btn-rounded waves-effect btn-circle btn-transparent edit "
                                            title="Delete"
                                          >
                                            <i className="bx bxs-trash"></i>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td
                                  colSpan="7"
                                  onClick={handleDocShow}
                                  className="bg-light cursor-pointer"
                                >
                                  <span className="d-flex align-items-center ">
                                    <i className="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i>
                                    <span className="pl-5 ">
                                      Add A Document
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        {check && (
                          <>
                            <Alert
                              variant={"danger"}
                              className={"text-capitalize"}
                            >
                              <p>MISSING REQUIRED FIELDS: </p>
                              <p>
                                <b>{missingItems?.join(", ")}</b>
                              </p>
                            </Alert>
                          </>
                        )}
                      </section>


                    </form>
                    <div className="button-navigators">
                      <button
                        disabled
                        className="btn btn-primary waves-effect kev-prev me-3"
                      >
                        <i className="mdi-arrow-left mdi font-16px ms-2 me-2"></i>{" "}
                        Previous{" "}
                      </button>
                      <button className="btn btn-primary waves-effect kev-nxt me-3">
                        Next{" "}
                        <i className="mdi mdi-arrow-right font-16px ms-2 me-2"></i>
                      </button>
                      <button
                        // type="submit"
                        onClick={handleSubmit}
                        className="btn btn-success kev-submit me-3 d-none"
                      // form={"my-form"}
                      >
                        Submit{" "}
                        <i className="mdi mdi-check-all me-2 font-16px"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* <!-- Add Bank details Modal --> */}
          <div>
            <Modal
              show={show}
              onHide={handleClose}
              className={"modal fade"}
              centered
            >
              <form onSubmit={handleAccountSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Add account details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group mb-4">
                        <label htmlFor="">
                          Select Bank.{" "}
                          <strong className="text-danger ">*</strong>
                        </label>
                        <select
                          className="form-control text-capitalize"
                          onChange={(e) => {
                            setbankAccountDetails(e.target.value);
                          }}
                          name="bank account"
                          required={true}
                        >
                          <option className="text-black font-semibold ">
                            select..
                          </option>
                          {banks
                            ?.map((bank) => {
                              return (
                                <option
                                  key={bank}
                                  value={bank + ":" + bank}
                                >
                                  {bank
                                    ?.toLowerCase()
                                    ?.replace(/_/g, " ")}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label htmlFor="">
                          Bank account number.{" "}
                          <strong className="text-danger ">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={bankAccountNumber}
                          onChange={(e) => setbankAccountNumber(e.target.value)}
                          placeholder="Enter account number"
                          required={true}
                        />
                      </div>
                      {/* <div className="form-group mb-4">
                        <label htmlFor="">
                          Percentage renumeration.{" "}
                          <strong className="text-danger ">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={percentageRemuneration}
                          onChange={(e) =>
                            setPercentageRemuneration(e.target.value)
                          }
                          placeholder="Enter % renumeration"
                          required={true}
                        />
                      </div> */}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className={"btn btn-grey"}
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    className={"btn btn-primary"}
                    type={"submit"}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>
          {/*edit bank details modal*/}
          <div>
            <Modal
              show={editAccountShow}
              onHide={handleEditAccountClose}
              className={"modal fade"}
              centered
            >
              <form onSubmit={handleEditAccount}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group mb-4">
                        <label htmlFor="">
                          Select Bank.
                          <strong className="text-danger ">*</strong>
                        </label>
                        <select
                          className="form-control"
                          onChange={(e) => {
                            editBankAccountDetails(e.target.value);
                          }}
                          name="bank account"
                          required={true}
                        >
                          <option className="text-black font-semibold ">
                            {editBankName}
                          </option>
                          {banks?.map((bank) => {
                            return (
                              <option
                                key={bank}
                                value={bank + ":" + bank}
                              >
                                {bank}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label htmlFor="">
                          Bank account number.{" "}
                          <strong className="text-danger ">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={editBankAccount}
                          onChange={(e) => setEditBankAccount(e.target.value)}
                          placeholder="Enter account number"
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className={"btn btn-grey"}
                    onClick={handleEditAccountClose}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    className={"btn btn-primary"}
                    type={"submit"}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>

          {/*document attachment modal*/}
          <div>
            <Modal
              show={docShow}
              onHide={handleDocClose}
              className={"modal fade"}
              centered
            >
              <form onSubmit={handleDocumentSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group mb-4">
                        <label htmlFor="">
                          Select Document Type.{" "}
                          <strong className="text-danger ">*</strong>
                        </label>
                        <select
                          className="form-control text-capitalize"
                          onChange={(e) => {
                            setdocumentTypeId(e.target.value);
                          }}
                          name="document type"
                          required={true}
                        >
                          <option className="text-black font-semibold ">
                            select..
                          </option>
                          {documentTypes
                            ?.map((dT) => {
                              return (
                                <option key={dT} value={dT}>
                                  {dT?.toLowerCase()}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="form-group mb-4">
                        <label htmlFor="">
                          Document Name.{" "}
                          <strong className="text-danger ">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={docName}
                          onChange={(e) => setdocName(e.target.value)}
                          placeholder="Enter document name"
                          required={true}
                        />
                      </div>
                      <div className="form-group mb-4">
                        <label htmlFor="">
                          Document Upload.{" "}
                          <strong className="text-danger ">*</strong>
                        </label>
                        <div className="input-group mb-0">
                          <label
                            className="input-group-text bg-info text-white cursor-pointer"
                            htmlFor="document1-1"
                          >
                            <i className="font-14px mdi mdi-paperclip"></i>{" "}
                            Attach File
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="document1-1"
                            onChange={(e) => handleFileRead(e)}
                            required={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className={"btn btn-grey"}
                    onClick={handleDocClose}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    className={"btn btn-primary"}
                    type={"submit"}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>

          {/*member modal*/}
          <div>
            <Modal
              show={memberShow}
              onHide={handleMemberClose}
              className={"modal fade"}
              centered
            >
              <form onSubmit={handleMemberSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-12">


                      <div className="form-group mb-4">
                        <label htmlFor="">
                          Member Names.{" "}
                          <strong className="text-danger ">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setdocName11(e.target.value)}
                          placeholder="Enter Member name"
                          required={true}
                        />
                      </div>

                      <div className="form-group mb-4">
                        <label htmlFor="">
                          Member Phone Number.{" "}
                          <strong className="text-danger ">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setdocument11(e.target.value)}
                          placeholder="Enter Member phone"
                          required={true}
                        />
                      </div>

                      <div className="form-group mb-4">
                        <label htmlFor="">
                          Member ID Number.{" "}
                          <strong className="text-danger ">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setidNumber(e.target.value)}
                          placeholder="Enter idnumber"
                          required={true}
                        />
                      </div>

                      <div className="form-group mb-4">
                        <label htmlFor="">
                          Member Designation.{" "}
                          <strong className="text-danger ">*</strong>
                        </label>
                        <select
                          className="form-control text-capitalize"
                          name={"sector"}
                          onChange={(e) => setDesignation(e.target.value)}
                        >
                          <option></option>
                          <option value={"Member"}>Member</option>
                          <option value={"ChairPerson"}>ChairPerson</option>
                          <option value={"Treasurer"}>Treasurer</option>
                          <option value={"Secretary"}>Secretary</option>

                        </select>
                      </div>

                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className={"btn btn-grey"}
                    onClick={handleMemberClose}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    className={"btn btn-primary"}
                    type={"submit"}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}


import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

function Dashboard() {
    const [openMenu, setOpenMenu] = useState(false);
    const [Groups, setGroups] = useState([]);
    const [totalGroups, settotalGroups] = useState('');
    const [totalApprovedGroups, settotalApprovedGroups] = useState('');
    const [citizensBenefited, setcitizensBenefited] = useState('');
    const [totalDisb, settotalDisb] = useState(4523444);
    const [totalRep, settotalRep] = useState(1523444);
    const [totaldefaulted, settotaldefaulted] = useState(452344);

    let formatCurrency = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "KES",
    });

    const getGroups = () => {

        let data1 = localStorage.getItem("data");
        data1 = data1 != null ? JSON.parse(data1) : [];

        let memener = 0;
        let approved = 0;
        for (let i = 0; i < data1.length; i++) {
            memener += data1[i].members.length;
            if (data1[i].approved === true) ++approved;
        }

        setGroups(data1);
        settotalGroups(data1.length)
        settotalApprovedGroups(approved)
        setcitizensBenefited(memener)
    };

    useEffect(() => {
        getGroups();
    }, []);

    var options = {
        chart: {
            height: 360,
            type: "bar",
            stacked: !1,
            columnWidth: "40%",
            vertical: true,
            toolbar: {
                show: !1,
            },
            zoom: {
                enabled: !0,
            },
        },
        plotOptions: {
            bar: {
                horizontal: !1,
                columnWidth: "40%",
                vertical: true,
                // endingShape: "rounded"
            },
        },
        dataLabels: {
            enabled: !1,
        },
        colors: ["#34b7f1", "#075e54", "#52c06e"],
        stroke: { show: !0, width: 2, colors: ["transparent"] },
        series: [
            {
                data: [32000, 1300000, 664300],
                name: "Disbursements",
            },
            {
                data: [12000, 804535, 66400],
                name: "Repayments",
            },
            {
                data: [3200, 13000, 66400],
                name: "Defaults",
            },
        ],
        xaxis: {
            categories: [
                "October",
                "November",
                "December"
            ],
        },
        yaxis: {
            title: {
                text: "Funds Distribution",
            },
        },
        legend: {
            position: "bottom",
        },
        fill: {
            opacity: 1,
        },
    };


    return (

        <div className="">

            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Dashboard</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item active">Dashboard</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="">
                                        <div className="flex-shrink-0 me-3">

                                        </div>
                                        <div className="flex-grow-1 align-self-center">
                                            <div className="text-muted mt-2">
                                                <h5 className="mb-1">Funds Admin Dashboard</h5>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>

                        <div className="col-xl-8">
                            <div className="row">
                                <div className="col-lg-12 px-sm-30px">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 align-self-center">
                                                    <div className="text-lg-left mt-4 mt-lg-0">
                                                        <div className="row">
                                                            <div className="col-3 col-sm-3 col-md-2">
                                                                <div>
                                                                    <div className="avatar-xs mb-3">
                                                                        <span className="avatar-title rounded-circle bg-secondary font-size-16">
                                                                            <i className="mdi mdi-office-building-outline text-white"></i>
                                                                        </span>
                                                                    </div>
                                                                    <p className="text-muted text-truncate mb-2">Groups</p>
                                                                    <h5 className="mb-0">{totalGroups}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-3 col-sm-3 col-md-2">
                                                                <div>
                                                                    <div className="avatar-xs mb-3">
                                                                        <span className="avatar-title rounded-circle bg-secondary font-size-16">
                                                                            <i className="mdi mdi-office-building-outline text-white"></i>
                                                                        </span>
                                                                    </div>
                                                                    <p className="text-muted text-truncate mb-2">Approved Groups</p>
                                                                    <h5 className="mb-0">{totalApprovedGroups}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-3 col-sm-3 col-md-2">
                                                                <div>
                                                                    <div className="avatar-xs mb-3">
                                                                        <span className="avatar-title bg-secondary rounded-circle font-size-16">
                                                                            <i className="mdi mdi-chat-outline text-white"></i>
                                                                        </span>
                                                                    </div>
                                                                    <p className="text-muted text-truncate mb-2">Citizens Benefited</p>
                                                                    <h5 className="mb-0">{citizensBenefited}</h5>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center mb-3">
                                                <div className="avatar-xs-2 me-3">
                                                    <span className="avatar-title rounded-circle bg-danger bg-soft text-danger  font-size-18">
                                                        <i className="mdi  mdi-cash-remove h2 mb-0 pb-0 text-danger"></i>
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <span>Total Disbursements</span>
                                                    {/* <div className="btn-group">
                                                                <button className="btn btn-link dropdown-toggle btn-sm px-0 text-left pt-0 card-month-filter" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    Feb 2022 <i className="mdi mdi-chevron-down"></i>
                                                                </button>
                                                                <div className="dropdown-menu">
                                                                    <a className="dropdown-item" href="#">Feb 2022</a>
                                                                    <a className="dropdown-item" href="#">Jan 2022</a>
                                                                    <a className="dropdown-item" href="#">Dec 2021</a>
                                                                    <a className="dropdown-item" href="#">Nov 2021</a>
                                                                    <a className="dropdown-item" href="#">Oct 2021</a>
                                                                    <a className="dropdown-item" href="#">Sep 2021</a>
                                                                    <a className="dropdown-item" href="#">Aug 2021</a>
                                                                </div>
                                                            </div> */}
                                                </div>
                                            </div>
                                            <div className="text-muted mt-4">
                                                <h4>{formatCurrency.format(totalDisb)}</h4>

                                                <div className="d-flex">
                                                    <span className="badge badge-soft-danger font-size-12"> 2% </span> <span className="ms-2 text-truncate">From previous period</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center mb-3">
                                                <div className="avatar-xs-2 me-3">
                                                    <span className="avatar-title rounded-circle bg-info bg-soft text-primary font-size-18">
                                                        <i className="mdi mdi-calendar-month  h2 text-info p-0 m-0"></i>
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <span>Total Repayments</span>

                                                </div>
                                            </div>
                                            <div className="text-muted mt-4">
                                                <h4 className="text-uppercase">{formatCurrency.format(totalRep)}<i className="mdi mdi-chevron-up ms-1 text-success"></i></h4>
                                                <div className="d-flex">
                                                    <span className="badge badge-soft-success font-size-12"> + 0.2% </span> <span className="ms-2 text-truncate">From previous period</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center mb-3">
                                                <div className="avatar-xs-2 me-3">
                                                    <span className="avatar-title rounded-circle bg-success bg-soft text-primary font-size-18">
                                                        <i className="mdi mdi mdi-calendar-text  h2 mb-0 pb-0 text-success"></i>
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <span>Total Defaulted</span>

                                                </div>
                                            </div>
                                            <div className="text-muted mt-4">
                                                <h4 className="text-uppercase">{formatCurrency.format(totaldefaulted)}<i className="mdi mdi-chevron-up ms-1 text-success"></i></h4>

                                                <div className="d-flex">
                                                    <span className="badge badge-soft-danger font-size-12"> 2% </span> <span className="ms-2 text-truncate">From previous period</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-sm-flex flex-wrap">
                                        <div className="d-flex flex-column">
                                            <h4 className="card-title mb-0">
                                                Funds Distribution Summaries
                                            </h4>
                                            <p className="text-muted mb-4"> </p>
                                        </div>
                                    </div>

                                    <div id="chart" className="apex-charts" dir="ltr">
                                        <Chart
                                            options={options}
                                            series={options.series}
                                            type="bar"
                                            height={"auto"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>


            </div>


        </div>

    )
}

export default Dashboard
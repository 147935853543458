import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from "react-router-dom";

function Login() {
  const [userInfo, setuserInfo] = useState({
    username: "",
    password: "",
  });

  const [showError, setShow] = useState(false);
  const [showSuccess, setShowS] = useState(false);

  const navigate = useNavigate();

  const [response, setResponse] = useState({
    results: [],
  });

  const [error, setError] = useState({
    message: "",
    color: ""
  });

  const handleChange = (event) => {
    setuserInfo({ ...userInfo, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (userInfo.username === "ephantusgithinji@gmail.com") {
      setShowS(true);
      setShow(false);
      localStorage.setItem('token', "token");
      window.location.href = '/home';
    } else {
      setShow(true);
      setShowS(false);

    }


  };



  useEffect(() => {
    setTimeout(function () {
      if (showError == true) {
        setShow(false);
      }
    }, 2000);
  }, [showError, showSuccess]);

  return (
    <div>
      <section class="bg-gray-50 dark:bg-gray-900">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
             FUND ADMIN LOGIN
          </a>
          <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Sign in
              </h1>
              {showError &&
                <div class="p-2 bg-red-800 items-center text-red-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                  <span class="flex rounded-full bg-danger-500 uppercase px-2 py-1 text-xs font-bold mr-3">Error</span>
                  <strong class="font-bold">Invalid Login Credentials!</strong>
                </div>
              }
              {showSuccess &&
                <div class="p-2 bg-green-800 items-center text-green-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                  <span class="flex rounded-full bg-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">Success</span>
                  <strong class="font-bold">Approved</strong>
                </div>
              }
              <form class="space-y-4 md:space-y-6" action="#">
                <div>
                  <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                  <input onChange={handleChange} type="email" name="username" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" />
                </div>
                <div>
                  <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                  <input onChange={handleChange} type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                </div>
                <button type="button"
                  onClick={handleSubmit}
                  class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">Sign in</button>
              </form>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Login;


import React, { useState } from 'react'

function Home() {
    const [openMenu, setOpenMenu] = useState(false)

    return (
        <div className='container-fluid'>

            {/* <img src="" alt="home page logo"/> */}
            <img src="assets/images/hustler2.jpg " alt=" " height="18 " className="auth-logo-dark "></img>
                      
        </div>
    )
}

export default Home
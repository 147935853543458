import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Register from './pages/Register';
import AddLandlord from './pages/AddLandlord';
import Groups from './pages/Landlords';

import React, { useEffect, useState } from "react";



function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const logout = () => {

    localStorage.removeItem('token');
    window.location.href = '/'
  }

  useEffect(() => {
    if (localStorage.getItem('token'))
      setLoggedIn(true);
    else
      setLoggedIn(false);
  }, [])

  return (
    <BrowserRouter>
      <div id="layout-wrapper">

        {loggedIn ?
          <div className="container-fluid p-0">

            <div className="vertical-menu">

              <div data-simplebar className="h-100">

                <div id="sidebar-menu">
                  <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title d-none" key="t-menu">ADMIN PORTAL</li>

                    <li>
                      <a href="/home" className="waves-effect">
                        <i className="bx bx-home-circle"></i><span>Dashboard</span>
                      </a>
                    </li>
                    <li>
                      <a href="/groups" className="waves-effect">
                        <i className="mdi mdi-shield-home"></i><span>Registered Groups</span>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>

            <header id="page-topbar">
              <div className="navbar-header">
                <div className="d-flex">
                  <div className="navbar-brand-box">
                    <a href="/home">

                      <h1 className="menu-title" key="t-menu">ADMIN PORTAL</h1>
                    </a>

                  </div>

                  <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                    <i className="fa fa-fw fa-bars"></i>
                  </button>

                  <form className="app-search d-none d-lg-block">
                    <div className="position-relative">
                      <input type="text" className="form-control" placeholder="Search..." />
                      <span className="bx bx-search-alt"></span>
                    </div>
                  </form>


                </div>

                <div className="d-flex">
                  <div className="dropdown d-inline-block d-lg-none ms-2">
                    <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="mdi mdi-magnify"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">

                      <form className="p-3">
                        <div className="form-group m-0">
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                            <div className="input-group-append">
                              <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>


                  <div className="dropdown d-inline-block">
                    <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img className="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg"
                        alt="Header Avatar" />
                      <span className="d-none d-xl-inline-block ms-1" key="t-henry">Ephantus</span>
                      <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      <div className="dropdown-divider"></div>

                      <span onClick={logout} className="dropdown-item text-danger">
                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">Logout</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </header>

            <div className="main-content">
              <Routes>
                <Route path='/home' element={<Dashboard />} />
                <Route path='/groups' element={<Groups />} />
                    <Route path="*" element={<Navigate to="/home" />}></Route>
              </Routes>
            </div>
          </div>
          :
          <>
            <header className='sticky top-0'>
              <nav class="bg-white border-gray-200 px-4 lg:px-6 pt-6 pb-2 dark:bg-gray-800">
                <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                  {/* LOGO  */}
                  <a href="/" class="flex items-center">
                    <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white"> FUND PORTAL</span>
                  </a>

                  <div class="flex items-center lg:order-2">
                    <a href="/login" class="text-indigo-700 dark:text-white hover:text-white hover:bg-indigo-700 font-semibold border-2 border-indigo-700 rounded text-base px-4 lg:px-5 py-1 lg:py-2 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">Log in</a>
                    <a href="/register" class="text-indigo-700 dark:text-white hover:text-white hover:bg-indigo-700 font-semibold border-2 border-indigo-700 rounded text-base px-4 lg:px-5 py-1 lg:py-2 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">New Registration</a>
                    <button type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" onClick={() => setOpenMenu(!openMenu)}>
                      <span class="sr-only">Open main menu</span>
                      <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                      <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button>
                  </div>

                  <div class={`${openMenu ? " " : "hidden"}  justify-between items-center w-full lg:flex lg:w-auto lg:order-1`}>
                    <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                      {/* <li>
      <a href="#" class="block py-2 pr-4 pl-3 text-black rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white">Pricing</a>
    </li> */}
                    </ul>
                  </div>
                </div>
              </nav>
            </header>

            <Routes>
                <Route path="/" element={<Home />} />
              <Route path='/login' element={<Login />} />

              <Route path='/register' element={<AddLandlord />} />
            </Routes>
          </>
        }
      </div>
    </BrowserRouter>
  );
}

export default App;
